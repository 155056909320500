<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('subject')">
                        <ValidationProvider name="subject" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.subject"
                                              :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('explanation')">
                        <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.explanation"
                                              :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="formData.faculty_code"
                                               :multiple="true"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="department_code" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('department')">
                            <department-selectbox v-model="formData.department_code"

                                                  :faculty_code="formData.faculty_code"
                                                  :multiple="true"
                                                  :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="program_code" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code"
                                               :faculty_code="formData.faculty_code"
                                               :department_code="formData.department_code"
                                               :multiple="true"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="file" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('file')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file
                                    v-model="formData.file"
                                    :placeholder="$t('select_file')"
                                    ref="fileInput"
                                ></b-form-file>
                                <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
                            </div>
                        </b-form-group>
                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                    </ValidationProvider>

                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <ValidationProvider name="start_date" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input type="datetime-local"
                                          v-model="formData.start_date"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    <ValidationProvider name="end_date" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input type="datetime-local"
                                          v-model="formData.end_date"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    <ValidationProvider name="roles" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('roles')">
                            <role-selectbox
                                v-model="formData.roles"
                                :multiple="true"
                                :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    <ValidationProvider name="program_levels" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('level')">
                            <parameter-selectbox v-model="formData.program_levels"
                                                 :multiple="true"
                                                 :validate-error="errors[0]"
                                                 code="program_levels">
                            </parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="formData.status"
                                :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

    // Services
    import AnnouncementsService from '@/services/AnnouncementsService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
    import RoleSelectbox from "@/components/interactive-fields/RoleSelectbox";
    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
    import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
    import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";

    export default {
        components: {
            RoleSelectbox,
            StatusSelectbox,
            ParameterSelectbox,
            ValidationProvider,
            ValidationObserver,
            FacultySelectbox,
            DepartmentSelectbox,
            ProgramSelectbox
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                program_code:null,
                faculty_code:null,
                department_code:null,
                formLoading: false,
                formData: {
                    faculty_code:null,
                    department_code:null,
                    program_code:null,
                    subject:null,
                    explanation:null,
                    file:null,
                    start_date:null,
                    end_date:null,
                    roles:null,
                    program_levels:null,
                    status:null
                },
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                AnnouncementsService.get(id)
                              .then(response => {
                                  this.formData.faculty_code=response.data.data.faculty_code.join(',')
                                  this.formData.department_code=response.data.data.department_code.join(',')
                                  this.formData.program_code=response.data.data.program_code.join(',')
                                  this.formData.end_date=response.data.data.end_date
                                  this.formData.start_date=response.data.data.start_date
                                  this.formData.roles=response.data.data.roles.join(',')
                                  this.formData.explanation=response.data.data.explanation
                                  this.formData.status=response.data.data.status
                                  this.formData.subject=response.data.data.subject
                                  this.formData.file=response.data.data.file
                                  this.formData.program_levels=response.data.data.program_level.join(',')
                              })
                              .catch(e => {
                                  this.showErrors(e)
                              })
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    let faculty_code = Array.isArray(this.formData.faculty_code)
                        ? this.formData.faculty_code.map(String)
                        : String(this.formData.faculty_code).split(',');
                    let program_code = Array.isArray(this.formData.program_code)
                        ? this.formData.program_code.map(String)
                        : String(this.formData.program_code).split(',');
                    let departman_code = Array.isArray(this.formData.department_code)
                        ? this.formData.department_code.map(String)
                        : String(this.formData.department_code).split(',');
                    let program_level = Array.isArray(this.formData.program_levels)
                        ? this.formData.program_levels.map(String)
                        : String(this.formData.program_levels).split(',');
                    let roles = Array.isArray(this.formData.roles)
                        ? this.formData.roles.map(String)
                        : String(this.formData.roles).split(',');
                    const formData = new FormData();
                    program_code.forEach((itm,index) => {
                        formData.append("program_code["+index+"]", itm);
                    })
                    faculty_code.forEach((itm,index) => {
                        formData.append("faculty_code["+index+"]", itm);
                    })
                    departman_code.forEach((itm,index) => {
                        formData.append("department_code["+index+"]", itm);
                    })
                    formData.append("end_date",  this.formData.end_date);
                    formData.append("start_date",  this.formData.start_date);
                    formData.append("subject", this.formData.subject);
                    formData.append("explanation", this.formData.explanation);

                    roles.forEach((itm,index) => {
                        formData.append("roles["+index+"]", itm);
                    })
                    program_level.forEach((itm,index) => {
                        formData.append("program_level["+index+"]", itm);
                    })
                    formData.append("file", this.formData.file);
                    formData.append("status", this.formData.status);
                    this.formLoading = true;
                    AnnouncementsService.update(this.formId, formData)
                                  .then(response => {
                                      this.$emit('updateFormSuccess', true);
                                      this.$toast.success(this.$t('api.' + response.data.message));
                                  })
                                  .catch(e => {
                                      this.showErrors(e, this.$refs.formModalValidate);
                                  })
                                  .finally(() => {
                                      this.formLoading = false
                                  })
                }
            }
        }
    }
</script>
